import { Suspense } from 'react';

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
} from '@lupa/ui/components/shadcn/sidebar';
import { useAuth } from '@lupa/ui/hooks/use-auth';
import { capitalize } from '@lupa/web/utils/capitalize';

import {
  AudioLines,
  Building2,
  PackageSearch,
  Search,
  UserSearch,
} from 'lucide-react';
import { Outlet, useLocation } from 'react-router-dom';

import { RouterLink } from '../components/RouterLink';

const sidebarNavGroups = [
  {
    groupName: 'Core',
    items: [
      {
        title: 'Stores',
        href: '/internal/store',
        icon: Building2,
      },
      {
        title: 'Remus',
        href: '/internal/remus',
        icon: AudioLines,
      },
      {
        title: 'Data Lookup',
        href: '/internal/lookupz',
        icon: Search,
      },
    ],
  },
  {
    groupName: 'Data monitoring',
    items: [
      {
        title: 'Bundle pricing',
        href: '/internal/bundle-pricing',
        icon: PackageSearch,
      },
      {
        title: 'Client balances',
        href: '/internal/client-balances',
        icon: UserSearch,
      },
    ],
  },
];

export function InternalLayout() {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const userFirstName = capitalize(user?.email?.split('@')?.[0] ?? 'friend');

  return (
    <SidebarProvider defaultOpen>
      <Sidebar>
        <SidebarHeader className='px-4'>
          <span className='text-base font-semibold'>Lupa Internal</span>
        </SidebarHeader>

        <SidebarContent>
          {sidebarNavGroups.map((group) => (
            <SidebarGroup key={group.groupName}>
              <SidebarGroupLabel>{group.groupName}</SidebarGroupLabel>
              <SidebarGroupContent>
                <SidebarMenu>
                  {group.items.map((item) => (
                    <SidebarMenuItem key={item.href}>
                      <SidebarMenuButton
                        asChild
                        isActive={pathname === item.href}
                      >
                        <RouterLink href={item.href}>
                          {item.icon && <item.icon />}
                          <span>{item.title}</span>
                        </RouterLink>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  ))}
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>
          ))}
        </SidebarContent>

        <SidebarFooter>
          <span className='text-muted-foreground text-center text-sm'>
            Remember, <span className='font-semibold'>{userFirstName}</span>:
            with great power comes great responsibility.
          </span>
        </SidebarFooter>
      </Sidebar>

      {/* Main content */}
      <main className='flex-1 overflow-y-auto bg-white'>
        <div className='p-6'>
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </div>
      </main>
    </SidebarProvider>
  );
}
