import React, { Suspense, lazy } from 'react';

import ErrorBoundaryFallback from '@lupa/ui/components/ErrorBoundaryFallback';
import { Toaster } from '@lupa/ui/components/Toaster';
import '@lupa/ui/styles/globals.css';
import { createTheme } from '@lupa/ui/theme';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';

import 'animate.css/animate.css';

/* import css vendors */
import 'dandelion-animated-slider/build/horizontal.css';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';

import RedirectComponent from './components/RedirectComponent';
import { GuestGuard } from './guards/GuestGuard';
import { withAuthGuard } from './hocs/withAuthGuard';
import { AuthLayout } from './layouts/AuthLayout';
import { InternalLayout } from './layouts/InternalLayout';
import { trpc, trpcClient } from './lib/trpc';
import { paths } from './paths';
import { globalSingleton } from './singletons/globalSingleton';

import './App.css';
import './vendors/animate-extends.css';
import './vendors/animate-slider.css';
import './vendors/hamburger-menu.css';
import './vendors/slick/slick-theme.css';
import './vendors/slick/slick.css';
import './vendors/top-loading-bar.css';

const RemusPage = lazy(() => import('./pages/RemusPage'));
const LandingPagePricing = lazy(
  () => import('./components/landing/LandingPagePricing'),
);

const AccountDeletionRequestPage = lazy(
  () => import('./pages/AccountDeletionRequestPage'),
);
const AppEmailConfirmationPage = lazy(
  () => import('./pages/AppEmailConfirmationPage'),
);
const AppointmentsNotesRecordingPage = lazy(
  () => import('./pages/AppointmentsNotesRecordingPage'),
);
const AuthRootPage = lazy(() => import('./pages/AuthRootPage'));
const BookingPage = lazy(() => import('./pages/BookingPage'));
const ClientTerms = lazy(() => import('./pages/ClientTerms'));
const Contact = lazy(() => import('./pages/Contact'));
const InternalStorePage = lazy(() => import('./pages/InternalStorePage'));
const InternalDataLookUpPage = lazy(
  () => import('./pages/InternalDataLookUpPage'),
);
const InternalDemoPage = lazy(() => import('./pages/InternalDemoPage'));
const InternalRemusPage = lazy(() => import('./pages/InternalRemusPage'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const ForgotPasswordPage = lazy(() => import('./pages/ForgotPasswordPage'));
const RegisterPage = lazy(() => import('./pages/RegisterPage'));
const Privacy = lazy(() => import('./pages/Privacy'));
const ReferralClientRegisterPage = lazy(
  () => import('./pages/ReferralClientRegisterPage'),
);
const StoreBookingPolicyPage = lazy(
  () => import('./pages/StoreBookingPolicyPage'),
);
const StorePreAppointmentConfirmationPage = lazy(
  () => import('./pages/StorePreAppointmentConfirmationPage'),
);
const Terms = lazy(() => import('./pages/Terms'));
const WaitlistPage = lazy(() => import('./pages/WaitlistPage'));
const PaymentSuccessPage = lazy(() => import('./pages/PaymentSuccessPage'));
const InternalBundlePricingPage = lazy(
  () => import('./pages/InternalBundlePricingPage'),
);
const InternalClientBalancePage = lazy(
  () => import('./pages/InternalClientBalancePage'),
);

const AuthRootLayout = withAuthGuard(AuthRootPage);

const baseRoutes = [
  // Base routes that both authenticated and unauthenticated users can access
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: '/pricing',
    element: <LandingPagePricing />,
  },
  {
    path: 'terms',
    element: <Terms />,
  },
  {
    path: 'privacy',
    element: <Privacy />,
  },
  {
    path: 'client-terms',
    element: <ClientTerms />,
  },
  {
    path: 'contact',
    element: <Contact />,
  },
  {
    path: 'redirect',
    element: <RedirectComponent />,
  },
  {
    path: 'app-email-confirmation',
    element: <AppEmailConfirmationPage />,
  },
  {
    path: 'booking/:id/*',
    element: <BookingPage />,
  },
  {
    path: '/store/:storeId/booking-policy',
    element: <StoreBookingPolicyPage />,
  },
  {
    path: '/store/:storeId/pre-appointment-confirmation/:visitTypeId',
    element: <StorePreAppointmentConfirmationPage />,
  },
  {
    path: '/store/:storeId/appointments/:id/notes-recording',
    element: <AppointmentsNotesRecordingPage />,
  },
  {
    path: 'waitlist',
    element: <WaitlistPage />,
  },
  {
    path: 'request-account-deletion',
    element: <AccountDeletionRequestPage />,
  },
  {
    path: '/store/:storeId/referral-client-register',
    element: <ReferralClientRegisterPage />,
  },
  {
    path: '/challenge',
    element: <RedirectComponent to='https://tally.so/r/wQd1b1' />,
  },
  {
    path: '/store/:storeId/payment-success',
    element: <PaymentSuccessPage />,
  },
];

const internalRoutes = [
  {
    path: '/internal',
    element: (
      <AuthRootLayout isInternal>
        <InternalLayout />
      </AuthRootLayout>
    ),
    children: [
      {
        path: 'store',
        element: <InternalStorePage />,
      },
      {
        path: 'remus',
        element: <InternalRemusPage />,
      },
      {
        path: 'lookupz',
        element: <InternalDataLookUpPage />,
      },
      {
        path: 'demo',
        element: <InternalDemoPage />,
      },
      {
        path: 'bundle-pricing',
        element: <InternalBundlePricingPage />,
      },
      {
        path: 'client-balances',
        element: <InternalClientBalancePage />,
      },
    ],
  },
];

const authenticatedRoutes = [
  {
    path: '/ai',
    element: (
      <AuthRootLayout>
        <Suspense fallback={null}>
          <Outlet />
        </Suspense>
      </AuthRootLayout>
    ),
    children: [],
  },
];

const authenticationRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'register',
        element: <RegisterPage />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPage />,
      },
    ],
  },
];

const remusRoutes = [
  {
    path: '/notes/*',
    element: (
      <AuthRootLayout>
        <Suspense fallback={null}>
          <RemusPage />
        </Suspense>
      </AuthRootLayout>
    ),
  },
  {
    path: '/remus/*',
    element: <RedirectComponent to={paths.remus.index} />,
  },
];

const customTheme = createTheme({
  colorPreset: 'purple',
  contrast: 'high',
  paletteMode: 'light',
  responsiveFontSizes: true,
});

function redirectWwwToNonWww() {
  // www.lupapets.com doesn't work with our backend config, so we redirect
  const currentUrl = window.location.href;
  if (currentUrl.startsWith('https://www.lupapets.com')) {
    const newUrl = currentUrl.replace(
      'https://www.lupapets.com',
      'https://lupapets.com',
    );
    window.location.replace(newUrl);
  }
}

function App() {
  redirectWwwToNonWww();

  const routes = [
    ...baseRoutes,
    ...internalRoutes,
    ...authenticatedRoutes,
    ...authenticationRoutes,
    ...remusRoutes,
  ];

  const router = createBrowserRouter(routes);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <trpc.Provider
        client={trpcClient}
        queryClient={globalSingleton.queryClient}
      >
        <QueryClientProvider client={globalSingleton.queryClient}>
          <ThemeProvider theme={customTheme}>
            <CssBaseline />
            <RouterProvider router={router} />
            <Toaster />
          </ThemeProvider>
        </QueryClientProvider>
      </trpc.Provider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
